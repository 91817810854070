export interface ScrollOptions {
    direction: "vertical" | "horizontal";
    offset: number | null;
}

export const useUtils = () => {

    const smoothScroll = (elem: HTMLElement | null = null, options: ScrollOptions | null = null, baseElem: any = window) : void => {
        if (elem) {
            if (options && options.direction && options.direction == 'horizontal') {
                baseElem.scroll({
                    left: elem.offsetLeft + (options.offset ? options.offset : 0),
                    behavior: 'smooth'
                });
            } else {
                baseElem.scroll({
                    top: elem.offsetTop + 200 + (options && options.offset ? options.offset : 0),
                    behavior: 'smooth'
                });
            }
        } else {
            baseElem.scroll({
                top: document.documentElement.scrollTop + (options && options.offset ? options.offset : 0),
                behavior: 'smooth'
            });
        }
    }

    const isInViewport = (element: HTMLElement): Boolean => {
        const rect = element.getBoundingClientRect();
        return (
            rect.top + rect.height >= 0 &&
            rect.top + (rect.height / 1) <= (window.innerHeight || document.documentElement.clientHeight)
        );
    }

    return { smoothScroll, isInViewport }
}