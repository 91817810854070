import throttle from 'lodash/throttle';
import { useUtils } from '@/hooks/use-utils';

const { isInViewport } = useUtils();

export default {
    beforeMount: function (el, binding, vnode) {
        el.inViewportEvent = throttle(() => {
            if(typeof binding.value === 'function') {
                binding.value(isInViewport(el));
            }
        }, 100);
        window.addEventListener('scroll', el.inViewportEvent);
    },
    unmounted: function (el) {
        window.removeEventListener('scroll', el.inViewportEvent);
    }
}