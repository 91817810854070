import { reactive } from 'vue';
import { useApi } from '@/hooks/use-api';

const { client } = useApi();
const baseURL = '/api/contact/';

export interface ContactState {
    emailSubmitted: boolean;
    error: string;
}

// Singleton
let contactState: ContactState;

export const useContact = () => {
    contactState = contactState || reactive<ContactState>({
        emailSubmitted: false,
        error: ''
    });

    const submitEmail = async (emailAddress: string): Promise<boolean> => {
        contactState.error = '';

        try {
            const res = await client.post(baseURL + 'submitEmail', { emailAddress });
            if (res.status == 200 && res.data) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            contactState.error = error;
            return false;
        }
    }

    return { contactState, submitEmail };
}