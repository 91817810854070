import { reactive, toRefs } from 'vue';
import { useApi, AuthHeader } from '@/hooks/use-api';
import { useUser } from '@/hooks/use-user';
import { useAppState } from '@/hooks/use-app-state';
import { User } from '@/types/user';

const baseURL = '/api/auth/';

export interface AuthState {
    loggedIn: boolean;
    hasVerified: boolean;
    token: null | string;
    error: string;
}

// Singleton
let authState: AuthState;

export const useAuth = () => {
    authState = authState || reactive<AuthState>({
        loggedIn: false,
        hasVerified: false,
        token: null,
        error: ''
    });

    function createUser(data: any): void {
        const { createUser } = useUser();
        createUser(<User>data);
    }

    const clearAuth = () => {
        localStorage.removeItem('token');
        authState.loggedIn = false;
        authState.hasVerified = false;
        authState.token = null;
        const { refreshSettings } = useAppState();
        refreshSettings();
    }

    const login = async (user: any) => {
        try {
            const { client } = useApi();
            const res = await client.post(baseURL + 'signin', { username: user.username, password: user.password });

            if (res.status == 200 && res.data) {
                localStorage.setItem('token', res.data.accessToken);
                authState.loggedIn = true;
                createUser(res.data);

                const { refreshSettings } = useAppState();
                refreshSettings();
            }
        } catch (error) {
            clearAuth();
            authState.error = error;
        }
    };

    const logout = () => {
        clearAuth();
        window.location.reload();
    };

    const register = async () => {

    };

    const verify = async () => {
        if (!authState.token) {
            authState.token = localStorage.getItem('token');
        }

        if (authState.token) {
            try {
                const { client } = useApi();
                const res = await client.get(baseURL + 'verify', { headers: AuthHeader() });
                if (res.status == 200 && res.data) {
                    authState.hasVerified = true;
                    authState.loggedIn = true;
                    createUser(res.data);

                    const { refreshSettings } = useAppState();
                    refreshSettings();
                }
            } catch (error) {
                clearAuth();
                authState.error = error;
            }
        }
    };

    return { ...toRefs(authState), login, logout, register, verify };
}