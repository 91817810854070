
import { defineComponent, ref } from "vue";
import { useAppState } from "@/hooks/use-app-state";
import { useAuth } from "@/hooks/use-auth";
import { useUser } from "@/hooks/use-user";
import { useContact } from "@/hooks/use-contact";
import { useToast } from "vue-toastification";

export default defineComponent({
  setup() {
    const { settings } = useAppState();
    const { loggedIn, logout } = useAuth();
    const { user } = useUser();
    const { contactState, submitEmail } = useContact();
    const toast = useToast();

    const formSubmitted = ref(false);
    const emailAddress = ref("");
    const saveEmailAddress = async () => {
      if (!formSubmitted.value) {
        formSubmitted.value = await submitEmail(emailAddress.value);

        if (contactState.error) {
          toast(
            "Er is iets mis gegaan bij het opslaan van je e-mailadres. Probeer later opnieuw!"
          );
        }
      }
    };

    return {
      settings,
      loggedIn,
      logout,
      user,
      formSubmitted,
      emailAddress,
      saveEmailAddress,
    };
  },
});
