
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";
import { useAuth } from "@/hooks/use-auth";

export default defineComponent({
  setup() {
    const route = useRoute();
    const { loggedIn } = useAuth();
    return {
      loggedIn,
      navbarAbsolute: computed(() => route.meta.navbarAbsolute),
    };
  },
});
