<template>
  <Navbar/>
  <main :class="{ loading: loading }">
    <div class="container mt-xs-1 mt-md-2 mb-xs-2">
      <router-view></router-view>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, watch } from "vue";
import debounce from "lodash/debounce";
import { useAppState } from "@/hooks/use-app-state";
import Navbar from "@/components/Navbar.vue";

export default defineComponent({
  setup() {
    const { loading, topbarMenuOpen } = useAppState();

    const setViewportHeight = debounce(() => {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      // Then we set the value in the --vh custom property to the root of the document
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }, 10);

    onMounted(() => {
      window.addEventListener("resize", setViewportHeight);
      setViewportHeight();
    });

    onUnmounted(() => {
      window.removeEventListener("resize", setViewportHeight);
    });

    watch(() => topbarMenuOpen.value, (value: boolean) => {
      if(value) {
        document.body.classList.add('overflow-hidden');
      } else {
        document.body.classList.remove('overflow-hidden');
      }
    });

    return { loading };
  },
  components: {
    Navbar
  }
});
</script>