<template>
  <section class="mt-xs-2">
    <div class="container-small">
      <h1>Login</h1>

      <form class="form mt-xs-15" @submit.prevent="handleLogin">
        <div class="form-field">
          <label for="field1">Naam</label>
          <input
            id="field1"
            type="text"
            v-model.trim="user.username"
            required
          />
        </div>

        <div class="form-field">
          <label for="field2">Wachtwoord</label>
          <input id="field2" type="password" v-model="user.password" required />
        </div>

        <div class="form-navigation">
          <input class="button" type="submit" value="Inloggen" />
        </div>
      </form>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useToast } from "vue-toastification";
import { useAuth } from "@/hooks/use-auth";

export default defineComponent({
  setup() {
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();
    const { loggedIn, login } = useAuth();
    const user = reactive({ username: null, password: null });

    const handleLogin = async (): Promise<void> => {
      if (user.username && user.password) {
        await login(user);

        if (loggedIn.value) {
          router.push(
            route.params.nextUrl ? `${route.params.nextUrl}` : { name: "Home" }
          );
        } else {
          toast("Inloggegevens zijn onjuist!");
        }
      }
    };

    return { user, router, route, toast, handleLogin };
  },
});
</script>

<style lang="scss">
</style>