import axios, { AxiosInstance } from 'axios';
import { useAppState } from '@/hooks/use-app-state';

export const AuthHeader = () => {
    let token = localStorage.getItem('token');

    if (token) {
        return { 'x-access-token': token };
    } else {
        return {};
    }
}

// Singleton
let client: AxiosInstance;

export const useApi = () => {
    function createAxiosInstance(): AxiosInstance {
        const { loading } = useAppState();
        
        var axiosInstance = axios.create({
            timeout: 1000 * 60 * 60 * 5
        });

        axiosInstance.interceptors.request.use(config => {
            loading.value = true;
            return config;
        }, error => {
            loading.value = false;
            return Promise.reject(error);
        });

        axiosInstance.interceptors.response.use(response => {
            loading.value = false;
            return response;
        }, error => {
            loading.value = false;
            return Promise.reject(error);
        });

        return axiosInstance;
    }

    client = client || createAxiosInstance();
    return { client };
}