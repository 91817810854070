
import { defineComponent, reactive } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useToast } from "vue-toastification";
import { useAuth } from "@/hooks/use-auth";

export default defineComponent({
  setup() {
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();
    const { loggedIn, login } = useAuth();
    const user = reactive({ username: null, password: null });

    const handleLogin = async (): Promise<void> => {
      if (user.username && user.password) {
        await login(user);

        if (loggedIn.value) {
          router.push(
            route.params.nextUrl ? `${route.params.nextUrl}` : { name: "Home" }
          );
        } else {
          toast("Inloggegevens zijn onjuist!");
        }
      }
    };

    return { user, router, route, toast, handleLogin };
  },
});
