<template>
  <nav class="nav" :class="{ 'nav-absolute': navbarAbsolute }">
    <div class="container">
      <div class="nav-list">
        <router-link class="nav-list-button" :to="{ name: 'Home' }">
          <span class="nav-list-button-icon"><icon icon="home"></icon></span
        ></router-link>
        <router-link v-if="loggedIn" class="nav-list-button" :to="{ name: 'Live' }">
          <span class="nav-list-button-icon"><icon icon="wifi"></icon></span
        ></router-link>
        <router-link v-if="loggedIn" class="nav-list-button" :to="{ name: 'SetlistOverview' }">
          <span class="nav-list-button-icon"><icon icon="stream"></icon></span
        ></router-link>
        <router-link class="nav-list-button" :to="{ name: 'SongRoot' }">
          <span class="nav-list-button-icon"><icon icon="music"></icon></span
        ></router-link>
        <router-link v-if="!loggedIn" class="nav-list-button" :to="{ name: 'Login' }">
          <span class="nav-list-button-icon"><icon icon="user-alt"></icon></span
        ></router-link>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";
import { useAuth } from "@/hooks/use-auth";

export default defineComponent({
  setup() {
    const route = useRoute();
    const { loggedIn } = useAuth();
    return {
      loggedIn,
      navbarAbsolute: computed(() => route.meta.navbarAbsolute),
    };
  },
});
</script>

<style lang="scss">
.nav {
  @include nav();
  @include nav-base($bg-color: transparent, $color: var(--white-500));
  backdrop-filter: blur(10px);

  @include max-md() {
    top: auto;
    bottom: 0;
    border-top: 1px solid var(--white-500);
  }

  @include max-sm() {
    @media (orientation: landscape) {
      position: absolute;
    }
  }

  &.nav-absolute {
    position: absolute;
  }

  > .container {
    @include nav-container();
  }

  &-list {
    @include nav-menu($hamburger-md: false);
    flex: 1 1 auto;
    justify-content: space-between;

    &-item {
      flex: 1 1 auto;
      text-align: center;
    }

    &-button {
      flex: 1 1 auto;
      text-align: center;

      &-icon {
        @include font-weight-bold;
        @include link-underline-custom($activeClass: $menuItemActiveClass);
      }
    }
  }
}
</style>