export enum UserRoles {
    USER = 'ROLE_USER',
    ADMIN = 'ROLE_ADMIN'
}

export interface User {
    id: string,
    username: string,
    email: string,
    roles: Array<UserRoles>,
    isAdmin: boolean
}