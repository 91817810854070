import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';
import Login from '@/views/Login.vue';
import Register from '@/views/Register.vue';

import { useAuth } from '@/hooks/use-auth';

const routes = [
  { path: '/:pathMatch(.*)*', redirect: '/' },
  {
    name: 'Home',
    path: '/',
    alias: '/home',
    component: Home
  },
  {
    name: 'Login',
    path: '/login',
    component: Login,
    meta: {
      requiresGuest: true
    }
  },
  {
    name: 'Register',
    path: '/registreren',
    alias: '/register',
    component: Register,
    meta: {
      requiresGuest: true
    }
  },
  {
    name: 'Profile',
    path: '/profiel',
    alias: '/profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'Live',
    path: '/live',
    component: () => import(/* webpackChunkName: "live" */ '@/views/Live.vue'),
  },
  {
    name: 'SetlistOverview',
    path: '/setlists',
    component: () => import(/* webpackChunkName: "setlists" */ '@/views/SetlistOverview.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'SongRoot',
    path: '/nummers',
    alias: '/songs',
    redirect: { name: 'SongOverview' },
    component: () => import(/* webpackChunkName: "song-root" */ '@/views/SongRoot.vue'),
    children: [
      {
        name: 'SongOverview',
        path: '',
        component: () => import(/* webpackChunkName: "song-overview" */ '@/views/SongOverview.vue'),
      },
      {
        name: 'SongCreate',
        path: 'toevoegen',
        alias: 'add',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "song-add" */ '@/views/SongCreate.vue'),
      },
      {
        name: 'SongDetail',
        path: ':artist/:title/:id',
        component: () => import(/* webpackChunkName: "song-detail" */ '@/views/SongDetail.vue'),
        meta: { navbarAbsolute: true }
      },
      {
        name: 'SongEdit',
        path: ':artist/:title/:id/wijzigen',
        alias: ':artist/:title/:id/edit',
        component: () => import(/* webpackChunkName: "song-edit" */ '@/views/SongCreate.vue'),
        meta: { requiresAuth: true },
        props: { edit: true }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition: any) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0, left: 0 }
    }
  }
})

router.beforeEach(async (to, from, next) => {
  const { verify, loggedIn, hasVerified } = useAuth();
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresGuest = to.matched.some(record => record.meta.requiresGuest);

  if (!hasVerified.value) {
    await verify();
  }

  if (requiresAuth && !loggedIn.value) {
    next({ name: 'Login', params: { nextUrl: to.fullPath } });
  } else if (requiresGuest && loggedIn.value) {
    next({ name: 'Home' });
  } else {
    next();
  }

  return false;
});

export default router;