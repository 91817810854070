<template>
  <h1 class="mb-xs-15">Kampvuuravond #1</h1>
  <p><strong>UITNODIGING</strong></p>
  <p>
    Wij willen samen met jou op zoek naar verbinding met God en elkaar via
    muziek en Zijn woord. Een avond voor iedereen die behoefte heeft om even
    stil te staan. In een kleinschalige en akoestische setting rond het
    kampvuur!
    <icon icon="guitar"></icon>
  </p>
  <p>
    Onze eerste avond hebben we gehad op donderdag 15 juli 2021. Wanneer we weer
    een avond organiseren zetten we dat hier neer!
  </p>
  <p class="mt-xs-2"><strong>OVER ONS</strong></p>
  <p>
    Wij zijn een kleine groep van drie personen (25-29 jaar) uit de omgeving van
    Zwolle die graag samen met elkaar muziek maken voor God. We willen dit graag
    door middel van deze eerste avond delen met meer mensen. Iedereen, jong,
    oud, is welkom!
  </p>
  <p class="mt-xs-2"><strong>BLIJF OP DE HOOGTE</strong></p>
  <p>
    Wil je op de hoogte blijven wanneer we weer een kampvuuravond organiseren?
    Laat dan hieronder je e-mailadres achter en we sturen je een mailtje!
  </p>
  <form
    v-if="!formSubmitted"
    class="form form-home mt-xs-15 mt-sm-4"
    @submit.prevent="saveEmailAddress"
  >
    <div class="form-field">
      <label for="field1">E-mailadres</label>
      <input id="field1" type="email" v-model.trim="emailAddress" required />
    </div>

    <div class="form-navigation">
      <input class="button" type="submit" value="Opslaan" />
    </div>
  </form>
  <p v-if="formSubmitted">
    Bedankt! We hebben je e-mailadres opgeslagen en we houden je op de hoogte!
  </p>
  <p class="mt-xs-2 mt-sm-4"><strong>VRAGEN?</strong></p>
  <p>
    Heb je vragen of wil je ons gewoon een berichtje sturen? Dat kan door te
    mailen naar:
    <a class="link" href="mailto:info@kampvuuravondje.nl"
      >info@kampvuuravondje.nl</a
    >
  </p>

  <button v-if="loggedIn" @click="logout" class="button mt-xs-4">
    Uitloggen
  </button>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useAppState } from "@/hooks/use-app-state";
import { useAuth } from "@/hooks/use-auth";
import { useUser } from "@/hooks/use-user";
import { useContact } from "@/hooks/use-contact";
import { useToast } from "vue-toastification";

export default defineComponent({
  setup() {
    const { settings } = useAppState();
    const { loggedIn, logout } = useAuth();
    const { user } = useUser();
    const { contactState, submitEmail } = useContact();
    const toast = useToast();

    const formSubmitted = ref(false);
    const emailAddress = ref("");
    const saveEmailAddress = async () => {
      if (!formSubmitted.value) {
        formSubmitted.value = await submitEmail(emailAddress.value);

        if (contactState.error) {
          toast(
            "Er is iets mis gegaan bij het opslaan van je e-mailadres. Probeer later opnieuw!"
          );
        }
      }
    };

    return {
      settings,
      loggedIn,
      logout,
      user,
      formSubmitted,
      emailAddress,
      saveEmailAddress,
    };
  },
});
</script>

<style lang="scss">
.form-home {
  @include min-sm {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>