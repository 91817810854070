import '@/assets/scss/style.scss';

import { createApp } from 'vue';
import Toast, { POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars, faArrowLeft, faTimes, faSyncAlt, faPlus, faSearch, faSortAlphaDown, faPlay, faStop, faPause, faHome, faStream, faMusic, faWifi, faUserAlt, faGuitar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import ClickOutside from './helpers/click-outside.directive.js';
import InViewport from './helpers/in-viewport.directive.js';

import App from './App.vue';
import router from './router';

const app = createApp(App);
app.use(router);
app.use(Toast, {
    position: POSITION.BOTTOM_CENTER,
    transition: 'Vue-Toastification__fade',
    hideProgressBar: true,
    icon: false
});

library.add(faBars, faArrowLeft, faTimes, faSyncAlt, faPlus, faSearch, faSortAlphaDown, faPlay, faStop, faPause, faHome, faStream, faMusic, faWifi, faUserAlt, faGuitar);
app.component('icon', FontAwesomeIcon);

app.directive('click-outside', ClickOutside);
app.directive('in-viewport', InViewport);

app.mount('body');