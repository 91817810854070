import { reactive, toRefs } from 'vue';
import { useAuth } from "@/hooks/use-auth";

export interface AppSettings {
    responsive: boolean,
    chords: boolean,
    numberSystem: boolean,
    transpose: boolean,
    capo: boolean,
    suggestions: boolean,
    metronome: boolean,
    backtrack: boolean,
    songNavigation: boolean,
    songOverviewSortActive: boolean,
}

const defaultAppSettings = {
    responsive: true,
    chords: true,
    numberSystem: false,
    transpose: true,
    capo: true,
    suggestions: true,
    metronome: false,
    backtrack: false,
    songNavigation: true,
    songOverviewSortActive: false
}

const defaultAppSettingsGuest = {
    responsive: true,
    chords: false,
    numberSystem: false,
    transpose: false,
    capo: false,
    suggestions: false,
    metronome: false,
    backtrack: false,
    songNavigation: true,
    songOverviewSortActive: true
}

export interface AppState {
    loading: boolean,
    topbarMenuOpen: boolean,
    settings: AppSettings
}

let appState: AppState;

export const useAppState = () => {
    const retrieveSettings = (): AppSettings => {
        const { loggedIn } = useAuth();

        if (!loggedIn.value) {
            return defaultAppSettingsGuest;
        } else {
            var stored = localStorage.getItem('app_settings');

            if (stored) {
                return Object.assign(defaultAppSettings, JSON.parse(stored));
            } else {
                return defaultAppSettings;
            }
        }
    }

    const refreshSettings = () => {
        if(appState) {
            appState.settings = retrieveSettings();
        }
    }

    appState = appState || reactive<AppState>({
        loading: false,
        topbarMenuOpen: false,
        settings: retrieveSettings()
    });

    const saveSettings = () => {
        const { loggedIn } = useAuth();
        if (loggedIn.value) {
            localStorage.setItem('app_settings', JSON.stringify(appState.settings));
        }
    }

    return { ...toRefs(appState), saveSettings, refreshSettings };
}