import { reactive } from 'vue';
import { User, UserRoles } from '@/types/user';

// Singleton
let user: User;

export const useUser = () => {

    const createUser = (userInstance: any) => {
        if (!user) {
            user = reactive(userInstance);
            user.isAdmin = user?.roles.includes(UserRoles.ADMIN);
        }
    }

    return { user, createUser };
}